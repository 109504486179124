<template>
  <div>
    <Loader v-if="loading"></Loader>

    <div class="wrraper" v-else>
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />

      <SearchSection
        @filterTable="searchTable"
        title="حركات الخزن"
      ></SearchSection>

      <Notfound v-if="!searchItems"></Notfound>

      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span> عدد حركات الخزن :</span>
              <span> {{ table_length }} </span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->
        <div class="table-print">
          <main class="main-table">
            <!-- start of  header of printSection  -->
            <PrintHead
              :title="'حركات الخزن'"
              :number="table_length"
            ></PrintHead>
            <!-- end of header of printSection  -->
            <v-data-table
              class="thumb"
              :headers="headers"
              :items="rows"
              :search="search"
              :loading="lodaing"
              :loading-text="$t('table.loadingData')"
              v-model="selected"
              :single-select="singleSelect"
              :items-per-page="rows.length"
              item-key="id"
              hide-default-footer
            >
              <!-- ================== You Can use any slots you want ================== -->
              <!-- ====== Select row field ====== -->

              <!-- Select no data State -->
              <template v-slot:no-data>
                {{ $t("table.noData") }}
              </template>
              <template v-slot:[`item.index`]="{ index }">
                {{ index + 1 }}
              </template>
              <!-- Select actions-->
              <template v-slot:[`item.amount`]="{ item }">
                <span
                  :class="{
                    redColor: item.amount < 0,
                    greenColor: item.amount > 0,
                  }"
                >
                  {{ Number(item.amount).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <!-- <span
                  :class="{
                    redColor: item.total < 0 ,
                    greenColor: item.total > 0,
                  }"
                >
                  {{ Number(item.total).toLocaleString() }}
                </span>  -->
                <span
                v-if="item.type == 'transfer_wallet_out' || item.type == 'out' || item.type == 'expense' || item.type == 'bank_out'"
                class="redColor"
                >
                  {{ Number(item.total).toLocaleString() }}
                </span> 
                <span
                v-else
                class="greenColor"
                >
                  {{ Number(item.total).toLocaleString() }}
                </span> 
              </template>
              <template v-slot:[`item.wallet_amount_before`]="{ item }">
                <span
                  :class="{
                    redColor: item.wallet_amount_before < 0,
                    greenColor: item.wallet_amount_before > 0,
                  }"
                >
                  {{ Number(item.wallet_amount_before).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.wallet_amount_after`]="{ item }">
                <span
                  :class="{
                    redColor: item.wallet_amount_after < 0,
                    greenColor: item.wallet_amount_after > 0,
                  }"
                >
                  {{ Number(item.wallet_amount_after).toLocaleString() }}
                </span>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <span v-if="item.client_type == 'customer'">
                  <span v-if="item.type == 'expense'"> مصروفات </span>
                  <span v-if="item.type == 'transfer_wallet_in'">
                    إيداع خزنة
                  </span>
                  <span v-if="item.type == 'transfer_wallet_out'"
                    >سحب خزنة
                  </span>
                  <span v-if="item.type == 'transfer'"> تحويل خارجي </span>
                  <span v-if="item.type == 'in'"> إيداع </span>
                  <span v-if="item.type == 'out'"> سحب </span>
                  <span v-if="item.type == 'bank_in'"> إيداع بنكي </span>
                  <span v-if="item.type == 'bank_out'"> سحب بنكي </span>
                  <span v-if="item.type == 'currency_in'"> شراء عملة </span>
                  <span v-if="item.type == 'currency_out'"> بيع عملة </span>
                </span>
                <span v-if="item.client_type == 'enterprise'">
                  <span v-if="item.type == 'transfer'"> تحويل خارجي </span>
                  <span v-if="item.type == 'in'"> إيداع مؤسسة </span>
                  <span v-if="item.type == 'out'"> سحب مؤسسة </span>
                  <span v-if="item.type == 'bank_in'"> إيداع بنكي </span>
                  <span v-if="item.type == 'bank_out'"> سحب بنكي </span>
                  <span v-if="item.type == 'currency_in'"> شراء عملة </span>
                  <span v-if="item.type == 'currency_out'"> بيع عملة </span>
                </span>
              </template>
              <template v-slot:[`item.desc`]="{ item }">
                <div class="_actions">
                  <v-icon
                    class="show"
                    small
                    @click="
                      modal.descriptionModalIsOpen = true;
                      setItemDesc(item.desc);
                    "
                  >
                    fal fa-eye
                  </v-icon>
                </div>
              </template>
              <template>
                <!-- Row -->
                <v-row>
                  <!-- Add & Delete -->
                  <v-col cols="12" sm="4"> </v-col>
                </v-row>
              </template>
              <!-- ======================== End Top Section ======================== -->
            </v-data-table>
          </main>
          <div class="showMoney" v-if="currencies_amount">
            <ul class="new_ul">
              <li v-for="item in currencies_amount" :key="item.name">
                <div class="state-count">
                  <span> الرصيد :</span>
                  <span
                    :class="{
                      redColor: item.amount < 0,
                      greenColor: item.amount > 0,
                    }"
                  >
                    {{ Number(item.amount).toLocaleString() }}
                    {{ item.name }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- End Main Section -->
      </div>
    </div>
    <DescModal
      :desc="selectedItemDesc"
      :handelModalApperance="modal.descriptionModalIsOpen"
      @close="closeDescModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "@/components/Charts/ChartComponent.vue";
import SearchSection from "@/components/Search/movement-search";
import DescModal from "@/components/modals/DescriptionModal.vue";
export default {
  name: "LockMovement",

  components: {
    StatsCard,
    SearchSection,
    DescModal,
  },

  props: ["id"],

  data() {
    return {
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "حركات الخزن",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "الرصيد الكلي",
          number: "10",
        },
        {
          id: 2,
          icon: "fal fa-images",
          title: "الرصيد بالريال",
          number: "10",
        },
        {
          id: 3,
          icon: "fal fa-images",
          title: "الرصيد بالجنيه",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,
      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
      },
      selectedItemDesc: null,

      // ========== Loding
      lodaing: false,
      table_length: null,
      currencies_amount: null,
      // excel data
      excelData: {
        name: "حركات الخزن",
        items: null,
        json_fields: {
          الحساب: "row_name",
          "نوع العملية": {
            field: "type",
            callback: (value) => {
              if (value == "expense") {
                return "مصروفات";
              } else if (value == "transfer_wallet_in") {
                return "إيداع خزنة";
              } else if (value == "transfer_wallet_out") {
                return "سحب خزنة";
              } else if (value == "transfer") {
                return "تحويل خارجي";
              } else if (value == "in") {
                return "إيداع";
              } else if (value == "out") {
                return "سحب";
              } else if (value == "bank_in") {
                return "إيداع بنكي";
              } else if (value == "bank_out") {
                return "سحب بنكي";
              } else if (value == "currency_in") {
                return "شراء عملة";
              } else if (value == "currency_out") {
                return "بيع عملة";
              }
            },
          },
          الخزنة: "wallet.name",
          المبلغ: {
            field: "total",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          العملة: "currency",
          "الرصيد قبل": {
            field: "wallet_amount_before",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "الرصيد بعد": {
            field: "wallet_amount_after",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          "تاريخ العملية": "created_at",
          وصف: "desc",
          بواسطة: "by.name",
        },
        footer: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "الحساب",
            value: "row_name",
            align: "center",
            width: 150,
          },
          {
            text: "نوع العملية",
            value: "type",
            align: "center",
            width: 100,
          },
          {
            text: "الخزنه",
            value: "wallet.name",
            align: "center",
            width: 100,
          },
          {
            text: "المبلغ",
            value: "total",
            sortable: false,
            align: "center",
            width: 100,
          },

          {
            text: "العملة",
            value: "currency",
            align: "center",
            width: 80,
          },
          {
            text: "الرصيد قبل",
            value: "wallet_amount_before",
            align: "center",
            width: 100,
          },
          {
            text: "الرصيد بعد",
            value: "wallet_amount_after",
            sortable: false,
            align: "center",
            width: 100,
          },
          {
            text: "تاريخ العملية",
            value: "created_at",
            sortable: false,
            align: "center",
            width: 100,
          },
          {
            text: "وصف",
            value: "desc",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 70,
          },
          {
            text: "بواسطة",
            value: "by.name",
            sortable: false,
            align: "center",
            width: 120,
          },
        ];
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    // ================ Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/walletoperation",
        params: {
          wallet_id: this.searchItems ? this.searchItems.lockId : null,
          balance_customer_id: this.searchItems
            ? this.searchItems.username
            : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.rows.forEach((ele) => {
            if (ele.client_type == "customer") {
              if (ele.type == "currency_in" || ele.type == "currency_out") {
                ele["row_name"] = ele.person_name;
              } else if (
                (ele.type == "transfer_wallet_in" && ele.to_wallet != null) ||
                (ele.type == "transfer_wallet_out" && ele.to_wallet != null)
              ) {
                ele["row_name"] = ele.to_wallet.name;
              } else if (ele.type == "expense") {
                ele["row_name"] = "------";
              } else {
                if (ele.balance_customer) {
                  ele["row_name"] = ele.balance_customer.name;
                } else {
                  ele["row_name"] = "------";
                }
              }
            }
            if (ele.client_type == "enterprise") {
              ele["row_name"] = ele.account.name;
            }
          });
          if (res.data.currencies_amount.length > 0) {
            this.currencies_amount = res.data.currencies_amount;
          }
          this.excelData.items = this.rows;
          this.excelData.footer = [];
          res.data.currencies_amount.forEach((val) => {
            this.excelData.footer.push(
              "الرصيد : " + " " + Number(val.amount).toLocaleString() + val.name
            );
          });
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("lock_and_deals")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

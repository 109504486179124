<template>
  <div>
    <main-nav></main-nav>
    <sidebar></sidebar>
    <transition name="fadeIn" mode="out-in">
      <router-view></router-view>
    </transition>
    <v-dialog v-model="dialogAlert" persistent max-width="500px">
      <v-card class="text-center py-5">
        <span class="icon-alert">
          <i class="fal fa-alarm-clock"></i>
        </span>
        <h6 class="text-alert">
          سيتم تسجيل الخروج من السيستم لعدم وجود عمل به لمدة
          <span> {{ timeDisActive }} </span>
        </h6>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MainNav from "../components/Layouts/MainNav.vue";
import Sidebar from "../components/Layouts/Sidebar.vue";
import Echo from "laravel-echo";
export default {
  name: "Homee",
  components: { MainNav, Sidebar },

  data() {
    return {
      isLoading: true,
      dialogAlert: false,
      timeDisActive: null,
      // socket
      userId: "",
    };
  },

  methods: {
    // socket
    receiveMessage() {
      if (localStorage.getItem("Golden_App_Token") != null) {
        window.io = require("socket.io-client");
        window.Echo = new Echo({
          broadcaster: "socket.io",
          // host: "https://golden.lb.aait-d.com:6065",
          host: "https://crm.soly-erp.com:6065",
          csrfToken: "Bearer " + localStorage.getItem("Golden_App_Token"),
          auth: {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("Golden_App_Token"),
            },
          },
        });
        // -----------------
        window.Echo.private(`golden-notification.${this.userId}`).notification(
          (payload) => {
            if (payload) {
              this.$iziToast.info({
                message: payload.title,
                backgroundColor: "#08e1ff",
                timeout: 5000,
                class: "note-alert",
                titleColor: "#0E59B6",
                position: "topRight",
              });
              this.$store.dispatch("get_notification");
              this.$store.dispatch("getTranshState");
              new Audio(require("./message.mp3")).play();
            }
          }
        );
      }
    },
    activeScreen() {
      var myInterval;
      var count = 0;
      // Active
      window.addEventListener("focus", () => {
        if (count > 900) {
          this.timeDisActive = count;
          if (this.timeDisActive < 60) {
            this.timeDisActive = count + " " + "ثانية";
          } else {
            this.timeDisActive = (count / 60).toFixed(1) + " " + "دقيقة";
          }
          this.dialogAlert = true;
          setTimeout(() => {
            this.$store.dispatch("auth_module/logOut");
          }, 1000);
        }
        window.clearInterval(myInterval);
        count = 0;
      });
      // Inactive
      window.addEventListener("blur", () => {
        myInterval = window.setInterval(() => {
          count++;
        }, 1000);
      });
    },
    launch_resize_dispatch() {
      this.$store.dispatch("sideNav_module/onResize");
    },
  },
  created() {
    // window.addEventListener("beforeunload", function () {
    //   localStorage.removeItem("Golden_App_fullName");
    //   localStorage.removeItem("Golden_App_userImg");
    //   localStorage.removeItem("Golden_App_Lang");
    //   localStorage.removeItem("Golden_App_User_Type");
    //   localStorage.removeItem("Golden_App_userPhone");
    //   localStorage.removeItem("Golden_App_Token");
    //   localStorage.removeItem("Golden_App_Theme");
    //   localStorage.removeItem("Golden_App_User_Id");
    // });
  },
  mounted() {
    if (localStorage.getItem("Golden_App_Token") != null) {
      this.userId = localStorage.getItem("Golden_App_User_Id");
      this.receiveMessage();
    }

    // this.activeScreen();
    // ===> Vuetify Direction
    if (this.$store.getters["lang_module/lang"] == "ar") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }

    if (this.$store.getters["theme/current_theme"] == "light") {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }

    // ===> scroll To Top Alwayse
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // ===> check if the page is login
    this.$store.dispatch("sideNav_module/onResize");
    if (this.$store.getters.resizeEventExisit == false) {
      window.addEventListener("resize", this.launch_resize_dispatch, true);
      this.$store.commit("changeRessizeEvent");
    }
  },
};
</script>

<style lang="scss" scoped></style>
